import React, { useEffect, useState } from 'react';
import { api } from '../../utils/fetchAPI';
import './BillingWindow.scss';
import { SolidgatePaymentForm } from './SolidgatePaymentForm';
import visaLogo from '../../media/visa.svg';
import mastercardLogo from '../../media/mastercard.svg';
import { ModalWindow } from '../ModalWindow';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getUserAsync } from '../../features/user/userSlice';
import { enqueueSnackbar } from 'notistack';
import classNames from 'classnames';

type Card = {
  id: string;
  userId: string;
  brand: string;
  number: string;
  bank: string;
  cardHolder: string;
};

export const BillingWindow: React.FC = () => {
  const [isVat, setIsVat] = useState(false);
  const [vatNumber, setVatNumber] = useState('');
  const [isModal, setIsModal] = useState(false);
  const [cards, setCards] = useState<Card[] | null>(null);
  const [isUpdatingCard, setIsUpdatingCard] = useState(false);
  const [isAddCard, setIsAddCard] = useState(false);
  const [cardToDelete, setCardToDelete] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  const getCardFromAPI = async () => {
    try {
      const res = await api.get<Card[]>('/cards');

      setCards(res);
    } catch (e) {
      console.error(e);
    }
  };

  const removeCardOnAPI = async () => {
    if (!cardToDelete) {
      return;
    }

    try {
      await api.delete('/cards/' + cardToDelete);

      dispatch(getUserAsync());
      getCardFromAPI();
      setCardToDelete(null);
    } catch (e) {
      console.error(e);
    }
  };

  const updateActiveCard = async (cardId: string) => {
    if (isUpdatingCard) {
      return;
    }

    // Note: temporarily solution; deactivation should be automated on BE
    if (cards?.find(card => card.id === user?.activeCard)) {
      enqueueSnackbar("You must deactivate the card before activation", { variant: 'error' });
      return
    }

    setIsUpdatingCard(true);
    try {
      await api.patch(`/cards/activate/${cardId}`);
      dispatch(getUserAsync());
    } catch (e) {
      console.error(e);
    }
    setIsUpdatingCard(false);
  };

  const handleDeactivate = async (cardId: string) => {
    setIsUpdatingCard(true);
    try {
      await api.patch(`/cards/deactivate/${cardId}`);
      dispatch(getUserAsync());
    } catch (e) {
      console.error(e);
    }
    setIsUpdatingCard(false);
  };

  const handleAddCard = () => {
    // Note: temporarily solution; deactivation should be automated on BE
    if (cards?.find(card => card.id === user?.activeCard)) {
      enqueueSnackbar("You must deactivate the card before activation", { variant: 'error' });
      return
    }

    setIsAddCard(true)
  }

  useEffect(() => {
    if (user && !user.paymentType) {
      setIsModal(true);
      setIsAddCard(true);
    }

    if (user && user.paymentType === 'card') {
      getCardFromAPI();
    }
  }, [user]);

  return (
    <div className="billing-window pos-rel">
      {isModal && (
        <ModalWindow
          title="Partnerway Billing"
          text="Before start to use Partnerway Google Application you should add your payment card. Then you will be able to use application and see all the information about your Google subscriptions."
          buttons={
            <button className="button button--primary" onClick={() => setIsModal(false)}>
              Okey
            </button>
          }
        />
      )}

      {cardToDelete && (
        <ModalWindow
          title="Card removing"
          text="Please take a note! If its the only payment card you have added to the Partnerway Google Application you wont be able to use the application and see all the information about your Google subscriptions."
          buttons={
            <>
              <button className="button button--secondary" onClick={() => setCardToDelete(null)}>
                Cancel
              </button>
              <button className="button button--primary" onClick={() => removeCardOnAPI()}>
                Remove
              </button>
            </>
          }
        />
      )}

      <div className="billing-window__header">
        <h1 className="page__title billing-window__title">Billing</h1>
        <div className="billing-window__payment">
          <span className="billing-window__sub-title">Payment Method</span>

          <div className="billing-window__header-button-group">
            {user?.paymentType && (
              <button className="billing-window__header-group-button button button--secondary">
                {user.paymentType}
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="billing-window__content">
        {user && (!user.paymentType || isAddCard) && (
          <>
            <div className="billing-window__form">
              <div className="billing-window__form-title">Payment Information</div>

              <input placeholder="Company name" className="billing-window__input input" type="text" />

              <div className="input--select">
                <select onChange={(e) => setIsVat(e.target.value === 'yes')} className="billing-window__input input">
                  <option value="" selected disabled hidden>
                    VAT payer
                  </option>

                  <option value="no">NO</option>

                  <option value="yes">YES</option>
                </select>
              </div>

              {isVat && (
                <div className="pos-rel mb-24">
                  <input
                    placeholder="VAT number"
                    className="billing-window__input--no-margin input w-100p"
                    type="text"
                    value={vatNumber}
                    onChange={(e) => setVatNumber(e.target.value)}
                  />
                  <span className="billing-window__input-info input-sub-info">Enter your VAT number</span>
                </div>
              )}

              <input placeholder="Company address" className="billing-window__input input" type="text" />
            </div>

            <div className="billing-window__form+ disp-none">
              <div className="billing-window__form-title">Bank details of the company</div>

              <input placeholder="Card number" className="billing-window__input input" type="text" />

              <div className="pos-rel mb-24">
                <div className="billing-window__input-group">
                  <input
                    placeholder="Month/Year"
                    className="billing-window__input--no-margin billing-window__input--70 input"
                    type="text"
                  />

                  <input
                    placeholder="CVV"
                    className="billing-window__input--no-margin billing-window__input--30 input"
                    type="text"
                  />
                </div>
                <span className="billing-window__input-info input-sub-info">Valid until</span>
              </div>

              <input placeholder="Card holder" className="billing-window__input input" type="text" />

              <div className="billing-window__buttons-group">
                <button className="button button--secondary">Update</button>

                <button className="button button--primary">Pay</button>
              </div>
            </div>

            <div className="billing-window__form">
              <div className="billing-window__form-title">Bank details of the company</div>

              <SolidgatePaymentForm isVatPayer={isVat} vatNumber={vatNumber} />
            </div>

            {/* <-- 'Bussiness Starter' Plan Block --> */}
            <div className="billing-window__plan-block+ disp-none">
              <span className="billing-window__plan-title">Bussines Standart</span>
              <p className="billing-window__plan-text billing-window__plan-text--regular">
                20% discount valid only for the first 20 users each month, until August 16, 2023. You can unsubscribe at
                any time.
              </p>
              <p className="billing-window__plan-text billing-window__plan-text--bolt">
                Get a month of subscription at 20% off.
              </p>
              <button className="button button--secondary button--fullwidth billing-window__button-secondary">
                Switch to yearly payment
              </button>
              <button className="button button--primary button--fullwidth billing-window__button-main">
                €9.36 per month
              </button>

              <span className="billing-window__plan-terms-price">Than €11.23 per month at Googlе.</span>
              <a href="#link" className="billing-window__plan-terms-link">
                Term of Service and Privacy Policy
              </a>
            </div>
          </>
        )}

        {/* TODO: Separate components for cards section and card item */}
        {user && user.paymentType === 'card' && cards && !isAddCard && (
          <div className="billing-window__cards">
            {cards.map((card) => (
              <div
                className={
                  'billing-window__card ' + (card.id === user.activeCard ? 'billing-window__card--active' : '')
                }
              >
                <div className="billing-window__card-info">
                  <img src={card.brand === 'VISA' ? visaLogo : mastercardLogo} alt="cardLogo" height="36" />

                  <div className="billing-window__card-details">
                    <span className="billing-window__card-number">
                      {card.brand} •••• {card.number.slice(12)}
                      <input type="radio" checked={card.id === user.activeCard} />
                    </span>

                    <span className="billing-window__card-bank">{card.bank}</span>
                  </div>
                </div>

                <div className="billing-window__card-footer">
                  {user.id === card.userId && user.activeCard !== card.id && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setCardToDelete(card.id);
                      }}
                      type="button"
                      className="button button--secondary"
                    >
                      Remove
                    </button>
                  )}
                  {card.id === user.activeCard && <div className="billing-window__card-current-tag">Current card</div>}
                  {card.id === user.activeCard && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeactivate(card.id)
                      }}
                      type="button"
                      className="button button--secondary"
                    >
                      Deactivate
                    </button>
                  )}
                  {card.id !== user.activeCard && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        updateActiveCard(card.id)
                      }}
                      type="button"
                      className={classNames("button button--secondary", {
                        'loader': isUpdatingCard
                      })}
                    >
                      Activate
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div className="billing-window__card" onClick={handleAddCard}>
              <div className="billing-window__add-card">+ Add new cards</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
