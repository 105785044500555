import React, { useEffect, useState } from 'react';
import Payment, { MessageType, SdkMessage } from '@solidgate/react-sdk';
import { api } from '../../../utils/fetchAPI';
import { Loader } from '../../Loader';
import { form } from './solidgateFormParams';
import { enqueueSnackbar } from 'notistack';

type Props = {
  isVatPayer: boolean;
  vatNumber: string;
};

export const SolidgatePaymentForm: React.FC<Props> = ({ isVatPayer, vatNumber }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [paymentData, setPaymentData] = useState({
    merchant: '',
    signature: '',
    paymentIntent: '',
  });

  const handleOnError = (e: SdkMessage[MessageType.Error]) => {
    const errMsg = Array.isArray(e.value.message) ? e.value.message[0] : e.value.message;

    enqueueSnackbar(errMsg || 'Unexpected error has occurred', { variant: 'error' });
    setIsLoading(false);
    console.warn(e);
  };


  const handleOnFail = (e: SdkMessage[MessageType.Fail]) => {
    enqueueSnackbar(e.message || 'Unexpected error has occurred', { variant: 'error' });
    setIsLoading(false);
    console.warn(e);
  };

  const handleOnMounted = (e: SdkMessage[MessageType.Mounted]) => {
    setIsLoading(false);
  };

  const handleOnSuccess = (e: SdkMessage[MessageType.Success]) => {
    // this handler works when user press the 'PAY' button
    console.log(e);
  };

  const handleOnSubmit = (e: SdkMessage[MessageType.Submit]) => {
    // this handler works when transaction processed by SolidGate
    console.log(e);
  };

  const handleOrderStatus = async (e: SdkMessage[MessageType.OrderStatus]) => {
    console.log(e);
    if (!e.response.transactions) {
      console.error('Error! No transactions found!');
      enqueueSnackbar('Error! No transactions found!', { variant: 'error' });
      return;
    }

    const transactions = Object.keys(e.response.transactions);

    if (!transactions.length || !e.response.transactions[transactions[0]]) {
      console.error('Error! No transaction found!!');
      enqueueSnackbar('Error! No transactions found!', { variant: 'error' });
      return;
    }

    const successTransaction = Object.values(e.response.transactions).find(
      (transaction) => transaction.status === 'success' && transaction.card_token?.token,
    );
    if (successTransaction) {
      setIsAddingCard(true);
      enqueueSnackbar('The temporary 1$ authorization charge is in the process', { variant: 'success' });

      const cardToken = successTransaction.card_token?.token
      const solidgateSubscriptionId = e.response.order?.subscription_id

      const { brand, number, bank, card_holder } = successTransaction.card;
      const customerData = {
        cardBrand: brand,
        cardNumber: number,
        cardBank: bank,
        cardHolder: card_holder,
        cardToken,
        solidgateSubscriptionId
      };

      try {
        await api.put('/me', { isVatPayer, vatNumber: vatNumber || null });
        await api.post('/cards', customerData);

        window.location.reload();
      } catch (e) {
        console.error(e);
      }

      setIsAddingCard(false);
    }
  };

  const getPaymentDataFromAPI = async () => {
    setIsLoading(true);
    try {
      type MerchantData = {
        merchant: string;
        signature: string;
        paymentIntent: string;
      };
      const merchantData: MerchantData = await api.get('/cards/merchant-data');

      setPaymentData({
        merchant: merchantData.merchant,
        signature: merchantData.signature,
        paymentIntent: merchantData.paymentIntent,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getPaymentDataFromAPI();
  }, []);

  return (
    <>
      {isAddingCard && (
        <div className="billing-loader-box">
          <Loader />
        </div>
      )}
      {paymentData.signature && (
        <Payment
          onError={handleOnError}
          onFail={handleOnFail}
          onMounted={handleOnMounted}
          onSubmit={handleOnSubmit}
          onSuccess={handleOnSuccess}
          onOrderStatus={handleOrderStatus}
          merchantData={paymentData}
          formParams={form.params}
          styles={form.styles}
        />
      )}

      {isLoading && (
        <div style={{ margin: '0 auto' }}>
          <Loader />
        </div>
      )}
    </>
  );
};
